import jQuery from 'jquery';
window.addEventListener('DOMContentLoaded', function () {
  jQuery('#body_wrap').addClass('is-loading');

  let $window = jQuery(window),
    view_w = $window.width(),
    target_w = 768;

  let windowWidth = window.innerWidth;

  window.addEventListener('resize', function () {
    if (windowWidth === window.innerWidth) {
      return;
    } else {
      view_w = $window.width();
      return view_w;
    }
  });
});

window.addEventListener('load', function () {
  disShade();
  jQuery('#body_wrap').removeClass('is-loading');
});

function disShade() {
  jQuery('#body_shade').delay(200).fadeOut(600);
}

window.addEventListener('DOMContentLoaded', function () {
  var fps = 400,
    refreshRate = 1000 / fps,
    timeOut = null;

  window.addEventListener('scroll', function () {
    timeOut = setTimeout(function () {
      let nowScrollPoint = jQuery(window).scrollTop();

      let $window = jQuery(window),
        view_w = $window.width(),
        target_w = 768;
    }, refreshRate);
  });

  var hdMenu = jQuery('.hd-menu_lv1 li'),
    hdMenu2 = jQuery('.hd-menu_lv2');

  hdMenu
    .on('mouseenter', function () {
      jQuery(this).addClass('is-active');
    })
    .on('mouseleave', function () {
      jQuery(this).removeClass('is-active');
    });
});
$(function () {
  var $elem = $('.top-visual_img img');
  var sp = '_sp.';
  var pc = '_pc.';
  var replaceWidth = 768;

  function imageSwitch() {
    var windowWidth = parseInt($(window).width());

    $elem.each(function () {
      var $this = $(this);

      if (windowWidth >= replaceWidth) {
        $this.attr('src', $this.attr('src').replace(sp, pc));
      } else {
        $this.attr('src', $this.attr('src').replace(pc, sp));
      }
    });
  }
  imageSwitch();

  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      imageSwitch();
    }, 200);
  });
});

window.addEventListener('DOMContentLoaded', function () {
  jQuery('.pageTop').hide();
  jQuery('#top .spmenu_switch').hide();

  const $window = jQuery(window);
  const target_w = 768;
  let view_w = $window.width();

  let windowWidth = window.innerWidth;

  this.window.addEventListener('resize', function () {
    if (windowWidth === window.innerWidth) {
      return;
    } else {
      view_w = $window.width();
      return view_w;
    }
  });

  const refreshRate = 144;
  const refreshTime = 1000 / refreshRate;

  let timeOut;
  this.window.addEventListener('scroll', function () {
    if (timeOut) {
      return;
    }

    timeOut = setTimeout(function () {
      timeOut = 0;

      let nowScrollPoint = window.scrollY;
      const headerHeight = jQuery('.hd').outerHeight();

      if (view_w <= target_w) {
        if (nowScrollPoint >= 20) {
          jQuery('.pageTop').fadeIn(200);
          jQuery('#top .spmenu_switch').stop().fadeIn(400);
        } else {
          jQuery('.pageTop').fadeOut(200);
          jQuery('#top .spmenu_switch').stop().fadeOut(400);
          jQuery('#top .spmenu_switch').removeClass('is-active');
          jQuery('#top .hd-menu').removeClass('is-active');
        }
      }
    }, refreshTime);
  });

  //== グローバルメニューSP
  jQuery('.spmenu_switch').on('click', function () {
    if (view_w <= target_w) {
      jQuery(this).toggleClass('is-active');
      jQuery('.hd-menu').toggleClass('is-active');
    }
  });
});

window.addEventListener('load', function () {
  /* スクロールトップ*/
  const headerHeight = jQuery('#header').outerHeight();
  const headerTop = jQuery('#header').offset().top;
  const urlHash = location.hash;
  const scrollSpeed = 400;

  if (urlHash) {
    jQuery('body,html').scrollTop(0);
    let target = jQuery(urlHash == '#' || urlHash == '' ? 'html' : urlHash);

    const position = target.offset().top - headerHeight;
    jQuery('body,html').animate({ scrollTop: position }, scrollSpeed, 'swing');
  }

  jQuery('a[href*="#"]').on('click', function () {
    const href = this.hash;

    if (href != '#top') {
      //== headerではない場合
      const target = jQuery(href == '#' || href == '' ? 'html' : href);

      const position = target.offset().top - headerHeight;
      jQuery('body,html').animate({ scrollTop: position }, scrollSpeed, 'swing');
    } else if (href == '#top') {
      //== headerの場合
      jQuery('body,html').animate({ scrollTop: headerTop }, scrollSpeed, 'swing');
    } else {
      //== それ以外はキャンセル
      return false;
    }

    return false;
  });
});

window.addEventListener('load', function () {
  /* 画像とテキスト（スクロール付き） */
  const $type47_photo = jQuery('.parts_img_type47_photo');
  const $type47_srcBox = jQuery('.parts_img_type47_text');
  let boxheight = $type47_photo.height();

  $type47_srcBox.css('height', boxheight - 72);

  window.addEventListener('resize', function () {
    const w = window.innerWidth;
    const x = 767;

    boxheight = $type47_photo.height();

    if (w <= x) {
      $type47_srcBox.css('height', '');
    } else {
      $type47_srcBox.css('height', boxheight - 72);
    }
  });

  /* よくある質問（クリックで回答を表示） */
  jQuery('.qaList dd').css('display', 'none');
  jQuery('.qaList dt').on('click', function () {
    jQuery(this).next().slideToggle();
  });
});

$(function () {
  //トピックス記事を折りたたむ
  $('.topics-wrap').hide();
  // .topic.clickableをクリックしたとき、子要素にある.topics-wrapを開閉する
  $('.topic.clickable .topic-title').click(function () {
    $(this).next('.topics-wrap').slideToggle();
    // 親要素に.is-activeを付け替える
    $(this).parent('.topic').toggleClass('is-active');
  });
});
